import useSurveyMetaList from '../../../../../hooks/hub/useSurveyMetaList';
import LoadingWidget from '../../../../components/loading-widget/loading_widget';
import { Info as InfoIcon, Check } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import './surveys.css';

function SurveyListItem({ survey }) {
    const submittedLabel = survey.submitted ?
        <div className='survey-submitted-check'><Check size={16} weight='bold'/></div> :
        <div className='survey-submitted-check-empty'/>;
    const navigate = useNavigate();
    const onClick = survey.submitted ?
        () => { } :
        () => { navigate('/survey/' + survey.survey_id); };

    return (
        <div className={'survey-list-item' + (survey.submitted ? ' submitted' : '')} onClick={onClick}>
            {submittedLabel}
            <div style={{width: '12px'}}/>
            <span className='survey-name'>{survey.name}</span>
        </div>
    );
}

function SurveyList({surveys}) {
    const surveyComps = Object.entries(surveys).map((e, i) => 
        <SurveyListItem key={i} survey={e[1]}/>
    );
    return (
        <div className='survey-list'>
            {surveyComps}
        </div>
    );
}

function SurveyListWrapper({ loading, surveys, error }) {
    if (loading)
        return <LoadingWidget centered={false}/>;
    else if (error !== null)
        return <span style={{color: 'var(--color-error)', margin: '0px 24px'}}>Could not load surveys: {error}</span>
    else if (surveys === null || surveys?.length === 0)
        return <span style={{color: 'var(--color-foreground-hinted)', margin: '0px 24px'}}>No content</span>
    else
        return <SurveyList surveys={surveys}/>;
}

export default function HubSubpageSurveys() {
    const [loading, surveys, error] = useSurveyMetaList();
    return (
        <div className='hub-subpage-surveys'>
            <div className='info-box'>
                <div className='info-icon'>
                    <InfoIcon size={20}/>
                </div>
                <div className='info-desc'>
                    <span>Here we list user surveys that you can participate in.</span>
                    <span>Survey participation is completely optional but helps us out a lot.</span>
                </div>
            </div>
            <h3>Current User Surveys</h3>
            <SurveyListWrapper loading={loading} surveys={surveys} error={error}/>
        </div>
    );
}